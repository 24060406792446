
.signupDialog {
  background: linear-gradient(180deg, #0AA59D 17.33%, #36B564 95.52%);
  border-radius: 10px;
}


.textfield {
  box-shadow: inset 2px 2px 2px rgba(153, 153, 153, 0.24);
  background: white;
  width: 320px;
}
.textfield > div {
  background-color: transparent !important;
}

.textfield-input {
}
