.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  background-image: linear-gradient(90deg, #0AA59D 17.33%, #36B564 95.52%);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.App-link {
  color: #61dafb;
}


/* Place text in the middle of the image */
.hero-text {
  color: white;
  width: 400px;
  display: flex;
  flex-direction: column;
}



@media only screen and (min-width: 900px) {
    #lefttrucks {
      width: 100%;
      height: 100%;
      background-image: url('./assets/cf-home-no-bg.png');
      background-repeat: no-repeat;
      background-size: contain;
  }
  .writeReview {
    min-width: 500px !important;
  }
  .hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
  }

  .bottom-bar {
    display: none;
  }
}

@media only screen and (max-width: 899px) {
  #lefttrucks {
  }
  .hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

.topBar-button-filled {
  background: #FFFFFF;
  border: 2px solid #FFFFFF;
  box-shadow: inset 2px 2px 2px rgba(153, 153, 153, 0.24);
  border-radius: 13px;
}
.topBar-button {
  border: 2px solid #FFFFFF;
  box-shadow: inset 4px 4px 4px rgba(153, 153, 153, 0.24);
  border-radius: 13px;
  color: #FFFFFF;
}

.cf-geocoder {
  display: inline-block;
  margin-left: 20px;
  vertical-align: top;
  margin-top: 5px;
}
.cf-geocoder > input {
  width: 400px;
  height: 40px !important;
  border: 3px solid #578FFF;
  box-shadow: inset 3px 3px 3px rgba(153, 153, 153, 0.24);
  border-radius: 20px;
  padding-left: 20px;
  font-size: 18px;
}
.cf-geocoder > input:focus {
  outline: none !important;
  border: 3px solid #579FFF;
  box-shadow: inset 5px 5px 5px rgba(153, 153, 153, 0.24);
}

.react-geocoder-results {
  position: absolute;
  max-width: 500px;
  z-index: 1000;
  background: #FFFFFF;
  box-shadow: inset 3px 3px 3px rgba(153, 153, 153, 0.24);
  border-radius: 20px;
}
.react-geocoder-results > div {
  margin: 10px;
}

.card > div {
  -webkit-user-drag: auto !important;
  touch-action: auto !important;
}

ul {
  list-style-type: none;
  padding: 0px;
}
body {
  background: #F7F7F7;
}

.mobileFilter {
  display: none !important;
}
.mobileSearchArea {
  display: none !important;
}
.mobileWarning {
  display: none !important;
}
.mobileSearchBar {
  display: none !important;
}


@media screen and (min-width: 900px){
    .main {
        display: flex;
    }
    .cf-mapbox-map {
        width: 100%;
    }
}

.mobileSearch {
  display: none;
  position: relative;
  top: -10px;
  background: #14A990;
  box-shadow: 4px 4px 20px 10px rgba(0, 0, 0, 0.1);
  color: white;
  height: 45px;
  width: 100px;
}

.pointer {cursor: pointer;}


@media screen and (max-width: 900px) {
  .mobileSearch {
    display: inline-block !important;
  }
  .cf-mapbox-map {
    display: none !important;
    width: 0px !important;
  }
  .overlays {
    pointer-events: none !important;
    touch-action: none !important;
  }
  #filters {
    display: none !important;
  }
  #loginbar {
    display: flex;
    justify-content: space-between;
  }

  .bottom-bar {
    overflow: hidden;
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100vw;
    height: 59px;
    display: flex;
    flex-direction: row;
    background-color: #FFF;
    justify-content: space-between;
    border-top: 1px solid black;
  }

  body::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .main {
    padding: 10px;
  }
  .cf-geocoder > input {
    width: 100%;
    height: 40px !important;
    border: 3px solid #578FFF;
    box-shadow: inset 3px 3px 3px rgba(153, 153, 153, 0.24);
    border-radius: 20px;
    padding-left: 20px;
    font-size: 18px;
  }

  .research-area-button {
    display: none !important;
  }

  
  .mobileWarning {
    display: block !important;
  }
  .mobileFilter {
    display: block !important;
  }
  .mobileSearchArea {
    display: inline-block !important;
  }

  .mobileSearchBar {
    display: flex !important;
  }
}

.adds_grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  min-height: 500px;
}


.pagination {
  margin: auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-widtH: 380px;
}


.pagination_number {
   border: 1px black solid;
   padding: 5px;
   border-radius: 4px;
   background-color: white;
}
.pagination_number_selected {
  padding: 5px;
  border: 1px blue solid;  
  background-color: lightblue;
}

