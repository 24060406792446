.scrollable {
  margin-right: 5px;
}


.big-text {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;

  color: #000000;
}

.medium-text {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 530;
  font-size: 22px;
  line-height: 31px;

  color: #000000;
}

.reg-text {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
}

.small-text {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.ul-margin {
  margin: 0px;
  margin-top: -5px;
}


.TopBar-row {
  display: flex;
  height: 10%;
}

button {
  -webkit-font-smoothing: antialiased;
  margin: 0px;
  font-family: "Open Sans", Tahoma, Geneva, sans-serif;
  letter-spacing: 0px;
  text-transform: none;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  transition: all 0.2s ease 0s;
  user-select: none;
  cursor: pointer;
  line-height: 22px;
  background: white;
  border: 1px solid rgb(209, 209, 213);
  border-radius: 2px;
  color: rgb(0, 106, 255);
  display: block;
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  outline: none;
  overflow: hidden;
  padding: 0px 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
