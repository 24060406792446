.card {
    background: #FFFFFF;
    margin: 30px;
    padding-bottom: 30px;
    /* background-image: url("./assets/aboutbackground.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: contain; */
}

.first-card {
  min-width: 50%;
  max-width: 600px;
}

.second-card {
  width: 50%;
}

.backgroundImage {
  position: relative;
  height: 200px;
  background-color: #0080ff;
  padding: 30px;
}

.bottomCard {
  position: absolute;
  bottom: 0;
}

.secondary-button {
  background: #14A990;
  border: 1px solid rgba(74, 214, 190, 0.2);
  border-radius: 10px;
  color: white;
  padding: 8px;
}

.review {
  border-bottom: 1px grey
}

.claim-section {
  margin-left: 30px;
}


@media screen and (max-width: 900px) {
  .card {
    width: 100% !important;
    margin: 0px;
    max-width: 100% !important;
    margin-bottom: 10px;
  }

  .second-card {
    width: 100% !important;
  }
  .first-card {
    width: 100% !important;
  }

  .claim-section {
    width: 90% !important;
    margin: 10px;
    margin-bottom: 30px;
    padding: 10px;
  }

  .carrier-page {
    display: flex;
    flex-direction: column;
  }

}
