.box {
  position: relative;
  display: inline-block;
  width: 95%;
  margin-left: 4px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box:hover::after {
    opacity: 1;
}


.innerBox {
  padding: 20px;
  display: flex;
}

.box img {
  width: 160px;
  height: 160px;
}


.card-name {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
}

.research-area-button {
  height: 45px;
  width: 100px;
  float: right;
  marginTop: 20px;
  margin-right: 20px;
  background: #14A990;
  box-shadow: 4px 4px 20px 10px rgba(0, 0, 0, 0.1);
  color: white;
}

.dot-research-area-button {
  display: inline-block;
  height: 43px;
  width: 100px;
  margin-left: 10px;
  background: #14A990;
  color: white;
}


.post-ad-button { 
  display: inline-block;
  height: 43px;
  width: 100px;
  margin-left: 10px;
  background: #14A990;
  color: white;
}


@media only screen and (min-width: 900px) { 
  .post-ad-button-desktop {
    display: inline-block;
    height: 43px;
    width: 100px;
    margin-left: 10px;
    background: #14A990;
    color: white;
  }
  .post-ad-button-mobile {
    display: none;
  }

}

@media only screen and (max-width: 899px) {
  .post-ad-button-mobile {
    display: block;
    position: fixed;
    width: 75px;
    height: 75px;
    border-radius: 100px;
    right: 40px;
    bottom: 80px;
    z-index: 10;
    background: #14A990;
    color: white;
  }

  .post-ad-button-desktop {
    display: none;
  }

  .box img {
    width: 100px;
    height: 100px;
  }
}
